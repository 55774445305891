<template>
    <div class="list-container no-sub-nav">
        <div class="row">
            <div class="action-list-header">
                <div class="circle-avatar shine"></div>
                <div class="lines shine"></div>
            </div>

            <div class="table-row-container">
                <div class="table-row-shimmer shine"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ActionsLoading",
};
</script>

<style lang="scss" scoped>
.table-row-shimmer {
    height: 55px;
    width: 100%;
    border-radius: 0.5em;
}
.lines {
    height: 1.2em;
    width: 100px;
    border-radius: 6px;
}
</style>