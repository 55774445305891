<template>
	<div class="chat">
		<div class="chat-feed">
			<div class="message-empty" v-if="messages && !messages.length">
				<p>No messages</p>
			</div>
			<div v-else v-for="message in messages" :key="message.id">
				<div
					class="message-row"
					:class="{
						'message-right': message.idFrom === user.uid,
					}"
				>
					<!-- Left user image -->
					<div class="message-user" v-if="message.idFrom != user.uid">
						<img
							v-if="message.photoFrom"
							:src="message.photoFrom"
							alt="User"
							class="user-img"
						/>
						<div v-else class="user-img">
							<fa class="user-icon" icon="user" />
						</div>
					</div>
					<div class="message-container" v-if="message.timestamp">
						<div class="message-content">
							<div
								v-if="message.content && message.content != ''"
							>
								{{ message.content }}
							</div>
							<div
								v-if="
									message.downloadUrl && message.type == '1'
								"
							>
								<img
									class="message-img"
									:src="message.downloadUrl"
									alt="Image"
								/>
							</div>
							<div
								v-if="
									message.downloadUrl && message.type == '3'
								"
							>
								<fa
									class="message-icon"
									icon="download"
									@click="openUrl(message.downloadUrl)"
								/>
							</div>
						</div>
						<div class="message-info">
							<span v-if="message.idFrom != user.uid"
								>{{ message.nameFrom }},
							</span>

							{{
								moment().diff(
									message.timestamp.toDate(),
									"months"
								) > 1
									? moment(message.timestamp.toDate()).format(
											"ddd, MMM D, YYYY"
									  )
									: moment(
											message.timestamp.toDate()
									  ).fromNow()
							}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<form class="chat-form rm-form" @submit.prevent>
			<div class="form-input">
				<input
					v-if="messageType == 'text'"
					v-model="newMessage"
					type="text"
					placeholder="Type your message..."
				/>
			</div>

			<div
				class="file-preview"
				v-if="messageType == 'image' || messageType == 'file'"
			>
				<img
					v-if="previewUrl"
					:src="previewUrl"
					alt="Selected image file preview"
				/>
				<span v-if="!previewUrl && selectedFile">
					Selected file: <b>{{ selectedFile.name }}</b>
				</span>
			</div>

			<div class="chat-icons" v-if="!previewUrl && !selectedFile">
				<!-- Hidden file input for selecting files -->
				<input
					type="file"
					ref="imageInput"
					@change="onFileChange"
					accept=".png,.jpg,.jpeg"
					style="display: none"
				/>
				<fa
					class="chat-icon"
					icon="image"
					@click="selectFile('imageInput')"
				/>

				<input
					type="file"
					ref="fileInput"
					@change="onFileChange"
					accept=".pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.txt"
					style="display: none"
				/>
				<fa
					class="chat-icon"
					icon="paperclip"
					@click="selectFile('fileInput')"
				/>
			</div>
			<div class="flex">
				<button
					v-if="previewUrl"
					class="btn btn-outline"
					@click="cancelPreview"
				>
					Cancel
				</button>
				<button
					v-if="!isPending"
					class="btn btn-primary"
					@click="sendMessage"
				>
					Send
				</button>
				<button v-else class="btn btn-primary" disabled>
					<div class="loading-padding">
						<page-loading />
					</div>
				</button>
			</div>
		</form>
	</div>
</template>

<script>
import { defineComponent, ref, watchEffect, computed, onMounted } from "vue";
import { projectFirestore } from "@/firebase/config";
import getCollection from "@/composables/getCollection";
import account from "@/composables/account";
import chatService from "@/composables/chatService";
import PageLoading from "@/components/PageLoading.vue";
import moment from "moment";

export default defineComponent({
	props: {
		threadId: {
			type: String,
			required: true,
		},
	},
	components: {
		PageLoading,
	},
	setup(props) {
		const { user } = account();
		const { sendText, sendFile, error, isPending } = chatService();

		const messageType = ref("text");

		const newMessage = ref("");

		const selectedFile = ref(null);
		const imageInput = ref(null);
		const fileInput = ref(null);

		const selectFile = (ref) => {
			if (ref == "imageInput") {
				messageType.value = "image";
				imageInput.value.click();
			} else if (ref == "fileInput") {
				messageType.value = "file";
				fileInput.value.click();
			}
		};

		const onFileChange = (e) => {
			selectedFile.value = e.target.files[0];
		};

		const previewUrl = computed(() => {
			return selectedFile.value && messageType.value == "image"
				? URL.createObjectURL(selectedFile.value)
				: "";
		});

		const openUrl = (url) => {
			window.open(url, "_blank");
		};

		const threadRef = projectFirestore
			.collection("threads")
			.doc(props.threadId);

		// Stream messages
		const messagesRef = threadRef
			.collection("messages")
			.orderBy("timestamp", "asc");
		const { documents: messages } = getCollection(messagesRef);

		const sendMessage = async () => {
			if (newMessage.value != "" && messageType.value == "text") {
				await sendText(props.threadId, newMessage.value);
				newMessage.value = "";
			} else if (messageType.value === "image" && selectedFile.value) {
				await sendFile(props.threadId, "images", selectedFile.value);
			} else if (messageType.value === "file" && selectedFile.value) {
				await sendFile(props.threadId, "files", selectedFile.value);
			}

			messageType.value = "text";
			selectedFile.value = null;
		};

		const cancelPreview = () => {
			messageType.value = "text";
			selectedFile.value = null;
		};

		// Scroll to the latest message
		watchEffect(() => {
			const domMessages = document.querySelectorAll(
				".chat-feed .message-row"
			);
			if (messages.value && domMessages.length > 0) {
				const lastMessage = domMessages[domMessages.length - 1];
				// Add a small delay before scrolling
				setTimeout(() => {
					lastMessage.scrollIntoView({
						behavior: "smooth",
						block: "start",
					});
				}, 1000);
			}
		});

		return {
			user,
			messages,
			newMessage,
			sendMessage,
			isPending,
			error,
			selectedFile,
			onFileChange,
			fileInput,
			imageInput,
			selectFile,
			messageType,
			previewUrl,
			cancelPreview,
			openUrl,
			moment,
		};
	},
});
</script>

<style lang="scss" scoped>
.chat {
	display: flex;
	flex-direction: column;
	height: 80vh;
}

.chat-feed {
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow-y: auto;
	padding: 1em 2em;
	border-left: 1px solid $rm-light-grey;
}

.message-empty {
	width: 100%;
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.message-row {
	display: flex;
	gap: 0.5em;
	width: 100%;
	align-items: flex-start;
	margin-bottom: 10px;

	.user-img {
		width: 1.5em;
		height: 1.5em;
		border: 1px solid $rm-off-white;
		border-radius: 50%;
		background-color: $rm-grey;
		margin-top: 4px;
		text-align: center;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.user-icon {
			color: white;
			margin-bottom: -3px;
			font-size: 1.2em;
		}
	}
}

.message-container {
	background-color: $rm-light-grey;
	padding: 10px;
	border-radius: 8px 8px 14px 0;
	max-width: 80%;
	.message-info {
		font-size: 0.5em;
		margin-top: 5px;
	}
	.message-content {
		display: flex;
		width: fit-content;
		gap: 1em;

		.message-img {
			width: 100%;
			object-fit: cover;
		}

		.message-icon {
			&:hover {
				cursor: pointer;
				color: $rm-pink;
			}
		}
	}
}

.message-right {
	justify-content: flex-end;
	.message-container {
		background-color: $rm-blue;
		color: white;
		border-radius: 8px 8px 0 14px;
		text-align: right;
	}
}

.message-icons {
	margin-left: 10px;
}

.message-icon {
	width: 20px;
	height: 20px;
	margin-right: 5px;
}

.chat-form {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1em;
	border-top: 1px solid $rm-light-grey;
	border-left: 1px solid $rm-light-grey;
}

.file-preview {
	max-height: 100%;
	margin-right: 10px;
	overflow: hidden;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.form-input {
	margin: 0 !important;
}

.chat-icons {
	display: flex;
	align-items: center;
	gap: 1em;
	margin: 0 1em;
	.chat-icon {
		font-size: 1.2em;
		&:hover {
			cursor: pointer;
			color: $rm-pink;
		}
	}
}

button {
	margin-left: 10px;
}
.loading-padding {
	padding: 5px 0;
}
</style>
