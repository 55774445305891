<template>
	<div class="tags-container fade-row">
		<div v-for="tag in item.tags" :key="tag">
			<div class="pill">
				<fa class="fa-tag" icon="tag" />
				<p>{{ tag }}</p>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const router = useRouter();
		const item = ref(props.item);
		const canEdit = ref(props.canEdit);

		const goToItem = () => {
			router.push({
				name: "EquipmentDetail",
				params: {
					id: item.id,
				},
			});
		};

		return {
			item,
			goToItem,
			canEdit,
		};
	},
});
</script>
<style lang="scss" scoped>
.tags-container {
	display: flex;
	align-items: center;
	overflow: auto;
	gap: 1em;
}
</style>
