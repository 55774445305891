<template>
	<div class="flex" :class="{ link: canEdit }" @click="goToItem()">
		<div class="circle-avatar" :style="`background-color:${item.color}`">
			{{ item.initials }}
		</div>

		<div class="title">{{ item.name }}</div>
		<div class="equipment-id">ID: {{ item.id }}</div>
	</div>
</template>

<script>
import { ref, defineComponent, watch } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
	props: {
		item: {
			type: Object,
			required: true,
		},
		canEdit: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	setup(props) {
		const router = useRouter();
		const item = ref(props.item);
		const canEdit = ref(props.canEdit);

		watch(
			() => props.item,
			(newItem) => {
				item.value = newItem;
			},
			{ deep: true }
		);

		const goToItem = () => {
			if (!canEdit.value) return;

			router.push({
				name: "EquipmentDetail",
				params: {
					id: item.value.id,
				},
			});
		};

		return {
			item,
			goToItem,
			canEdit,
		};
	},
});
</script>
<style lang="scss" scoped>
.title {
	width: 180px !important;
	font-weight: 600;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.equipment-id {
	width: 180px !important;
	font-weight: 600;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.link {
	&:hover {
		cursor: pointer;
		color: $rm-pink;
	}
}
</style>
