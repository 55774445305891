<template>
	<Sidebar />
	<div class="right-content">
		<div class="right-nav flex-d">
			<div class="page-title">
				<h2>Actions</h2>
			</div>
			<div class="page-links">
				<span
					id="AssignedActionsList"
					:class="
						visible === 'AssignedActionsList'
							? 'active'
							: 'inactive'
					"
					@click="displayComponent($event)"
					>Assigned</span
				>
				<span
					id="OwnedActionsList"
					:class="
						visible === 'OwnedActionsList' ? 'active' : 'inactive'
					"
					@click="displayComponent($event)"
					>Owned</span
				>
				<span
					id="CommentingActionsList"
					:class="
						visible === 'CommentingActionsList'
							? 'active'
							: 'inactive'
					"
					@click="displayComponent($event)"
					>Commenting</span
				>

				<span
					v-if="role == 'Admin'"
					id="AllActionsList"
					:class="
						visible === 'AllActionsList' ? 'active' : 'inactive'
					"
					@click="displayComponent($event)"
					>Company</span
				>
				<span
					id="CompletedActionsList"
					:class="
						visible === 'CompletedActionsList'
							? 'active'
							: 'inactive'
					"
					@click="displayComponent($event)"
					>Completed</span
				>
			</div>
		</div>
		<div class="page-content">
			<div class="page-detail">
				<actions-list :actionsList="visible" />
			</div>
		</div>
	</div>
	<router-view v-slot="{ Component }">
		<transition name="fade" mode="in-out">
			<component :is="Component" />
		</transition>
	</router-view>
</template>

<script>
import Sidebar from "../../components/Sidebar.vue";
import account from "../../composables/account";
import { ref } from "@vue/reactivity";
import ActionsList from "../../components/actions/ActionsList.vue";
import { useMeta } from "vue-meta";

export default {
	components: {
		Sidebar,
		ActionsList,
	},
	name: "Actions",
	setup() {
		useMeta({ title: "Actions" });
		const { user, role, companyId } = account();

		const visible = ref("AssignedActionsList");

		const displayComponent = (event) => {
			var componentId = event.currentTarget.id;

			switch (componentId) {
				case "OwnedActionsList":
					visible.value = "OwnedActionsList";
					break;
				case "AssignedActionsList":
					visible.value = "AssignedActionsList";
					break;
				case "CommentingActionsList":
					visible.value = "CommentingActionsList";
					break;
				case "CompletedActionsList":
					visible.value = "CompletedActionsList";
					break;
				case "AllActionsList":
					visible.value = "AllActionsList";
					break;
				default:
					visible.value = "AssignedActionsList";
			}
		};

		return { user, displayComponent, visible, role };
	},
};
</script>

<style lang="scss" scoped>
.right-nav {
	padding: 10px 15px;
}

.page-links {
	font-size: 12px !important;
	gap: 0.5em !important;
	text-align: center;

	@include respond(small) {
		font-size: 12px !important;
		gap: 2em !important;
	}
	@include respond(medium) {
		font-size: 13px !important;
		gap: 0.7em !important;
	}
	@include respond(large) {
		gap: 4em !important;
		font-size: 1em !important;
	}
}
</style>
